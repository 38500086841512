.main{
    max-width:1500px;
    margin:100px auto 0 auto;
    padding-top:55px!important;
    padding-bottom:75px;
    padding:0 40px;
    position: sticky;
}
.main1{
    display:grid;
    grid-template-columns:repeat(3,auto);
    margin:0 0 0 -22px;
}
.lefttmain{
    position:relative;
    width:80%;
    margin:0 9% 0 9%;
    box-sizing:border-box;
    float:left;
}
.rightmain{
    position:relative;
    min-width:300px;
    width:80%;
    margin:0 9% 0 9%;
    box-sizing:border-box;
    float:right;
}
.span{
    border-left: 1px solid #d6d6d6;
}