.login{
    width:80%;
    text-align:center;
    margin-bottom:15px;
    margin-left: 60px;
}
.logheader{
    text-align:center;
    margin-bottom:50px;
}
.logheader h1{
    font-family:Poppins,sans-serif;
    font-size:1.5em;
    font-weight:700;
    line-height:1.3em;
    letter-spacing:.1em;
    color:#000;
}
.logheader p{
    width:60%;
    text-align:center;
    font-size:12px;
    font-weight:400;
    margin:10px auto 0 auto;
    font-family:Poppins,sans-serif;
    line-height:1.5;
    letter-spacing:.025em;
    color:#000;
    display:block;
}
.emaillabel{
    display:block;
    font-weight:400;
    font-family:Poppins,sans-serif;
    line-height:1.5;
    font-size:.75em;
    letter-spacing:.3em;
    margin-bottom:5px;
    box-sizing:border-box;
    color:#000;
    text-align:left;
    margin-left:70px;
}
.emailinput{
    border:1px solid #d6d6d6;
    width:80%;
    margin-bottom:30px;
    display:block;
    box-sizing:border-box;
    color:inherit;
    background-color:inherit;
    padding:8px 10px;
    line-height:1.5;
    font-family:Poppins,sans-serif;
    letter-spacing:.025em;
    margin-left:70px;
}
.passdivmain{
    margin:0 0 0 -22px;
    display:grid;
    grid-template-columns:repeat(2,2fr);
}
.passdivleft{
    width:50%;
    float:left;
    padding-left:22px;
    min-height:1px;
    margin-left:70px;
}
.passlabelleft{
    text-align:left;
    letter-spacing:.3em;
    font-size:.75em;
    font-weight:400;
    margin-bottom:10px;
    display:block;
}
.passdivright{
    margin-left:80px;
    width:50%;
    text-align:right;
    float:right;
    padding-left:22px;
    min-height:1px;
}
.passlableright{
    margin-left:20px;
    text-align:right;
    font-size:.85em;
    font-weight:400;
    margin-bottom:10px;
    display:block;
}
.passinput{
    border:1px solid #d6d6d6;
    width:80%;
    margin-bottom:30px;
    display:block;
    box-sizing:border-box;
    color:inherit;
    background-color:inherit;
    padding:8px 10px;
    line-height:1.5;
    font-family:Poppins,sans-serif;
    letter-spacing:.025em;
    margin-left:70px;
}
.btnsignin{
    margin-bottom:19px;
    margin-left: 5px;
    width:80%;
    padding:13px 20px;
    background-color:black;
    color:white;
}
.lastdiv{
    width: 80%;
    margin-left:65px;
    margin-top: 10px;
}
.facebookbtn{
    width:49%;
    padding:15px 20px;
    float: left;
    color: #fff;
    background-color: #ffb3d2;
    border: none;
}
.googlebtn{
    width:49%;
    padding:15px 20px;
    float:right;
    color: #fff;
    background-color: #ffb3d2;
    border: none;
}