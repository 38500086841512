.address2{
    display: flex;
    padding-left: 80px;
    padding-right: 80px;
    /* background-color: rgb(219, 91, 91); */
    padding-top: 50px;
    /* margin-top: 0; */
    /* position: sticky; */
}
.details2{
    width: 50%;
    /* background-color: blueviolet; */
    padding: 15px;
    text-align: left;
}

.form2{
    margin-top: 25px;
    font-size: larger;
    font-weight: 600;
    
}
.form2>div{
    /* gap: 10px; */
    margin-bottom: 15px;
    justify-content: space-between;
    display: flex;
}

.form2>div:last-child{
margin-top: 40px;
}
.form2>div:last-child{
color: rgb(255, 64, 102);
}
.form2>div:last-child>:last-child{
    background-color: black;
    color: white;
    padding: 10px;
}
.total2{
    width: 40%;
    /* background-color: aqua; */
    padding-top: 50px;
    padding-left: 50px;
    border-left: 1px solid rgb(172, 165, 165);
    
}
.coupon2{
    height: 120px;
    border-top: 1px solid rgb(172, 165, 165);
    padding-top: 15px;
    border-bottom: 1px solid rgb(172, 165, 165);
    justify-content: left;
}
.coupon2>input{
    width: 60%;
    padding: 5px;
    border: 1px solid rgb(170, 168, 168);
    border-radius: 10px;
    margin-right: 20px;
    margin-left: 0px;
}
.coupon2>button{
    background-color: black;
    color: white;
    padding: 5px;
    width: 80px;
}