.navbar{
    width: 100%;
    justify-content: right;
   padding-top: 10px;
   background-color: white;
   position: fixed;
   top: 0;
}
.upper{
    width: 100%;
    display: flex;
    
}
.upper>div:first-child{
    width: 15%;
    padding-top: 10px;
}
.upper>div:nth-child(2){
    width: 60%;
    
}
.img{
    margin-left: 40%;
}
.upper>div:last-child{
    width: 25%;
    padding-top: 10px;
    
}
.icons{
    width: 100%;
    justify-content: right;
    display: flex;
}
.icons>*{
    margin-right: 35px;
}
.lower{
    width: 100%;
    margin-top: 10px;
    padding-bottom: 15px;
}
.links{
    width: 70%;
    margin: auto;
    
}
.links>*{
    margin-right: 20px;
}
.cart{
    width: 400px;
    right: -400px;
    height: 100%;
    /* background-color: teal; */
    display: block;
    transform: translate(-400px);
    transition-duration: .45s;
    overflow: scroll;
    position: fixed;
    top: 0px;
    bottom: 0;
    max-width: 95%;
    color: #000;
    background-color: #f6f7f8;
    box-shadow: 0 0 150px rgb(22, 19, 19);
    transition: transform .25s cubic-bezier(.165,.84,.44,1);
}

.cart_header{
    display: flex;
margin-top: 40px;
padding-left: 25px;
/* background-color: aqua; */
padding-bottom: 35px;
border-bottom: 1px solid rgb(36, 34, 34);
}
.cart_header>div:first-child{
    width: 75%;
    
    text-align: left;
    font-size:x-large;
    font-weight: 300;
}
.cart_header>div:nth-child(2){
    width: 25%;
    justify-content: right;
    
}
.cart_footer{
    padding-top: 15px;
}
.prodiv{
    min-height: 400px;
}
.prod{
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}
.checkout{
    
    border-top: 1px solid black;
    bottom: 0;
    padding-top: 50px;
    padding-bottom: 20px;

}
.btn{
    width: 80%;
    margin: auto;
    background-color: #000;
    color: #f6f7f8;
    padding:10px ;
}