.Dropdown3{
    width: 85%;
    /* margin: auto; */
    background-color: white;
    /* margin-top: -40px; */
    display: grid;
    grid-template-columns: repeat(8,1fr);
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    position: fixed;
    top: 90px;
    left: 8%;

}
.ele{
    display: flex;
    align-items: center;
    margin: 5px;
    font-size: small;
}
.ele:hover{
    color: rgb(55, 207, 207);
}