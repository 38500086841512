.SingleProduct{
    margin-bottom: 20px;
    cursor: pointer;
   
}
.SingleProduct > img{
    width: 100%;
    height: 500px;
    
}
.SingleProduct > p{
    text-align: center;
    padding: 0;
    margin: auto;
    /* margin: 0%; */
    color: #000000;
    font-size: 12px;
    
}
.SingleProduct button{
    background-color: white;
    border: 0;
    font-size: 12px;
    margin-top: 0;
    margin: auto;
    width: 100%;
}
