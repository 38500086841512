.address{
    display: flex;
    padding-left: 80px;
    padding-right: 80px;
    /* background-color: rgb(219, 91, 91); */
    padding-top: 50px;
    /* margin-top: 0; */
    /* position: sticky; */
}
.details{
    width: 50%;
    /* background-color: blueviolet; */
    padding: 15px;
    text-align: left;
}
.details img{
    width: 40%;
    margin: auto;
}
.form{
    margin-top: 25px;
    font-size: larger;
    font-weight: 600;
    
}
.form>div{
    /* gap: 10px; */
    margin-bottom: 15px;
    justify-content: space-between;
    display: flex;
}
.form>div:nth-child(2)>input{
    margin-top: 30px;
    width: 45%;
    padding: 5px;
    border: 1px solid rgb(170, 168, 168);
    border-radius: 10px;
}
.form>div:nth-child(3) input{
    width: 100%;
    padding: 5px;
    border: 1px solid rgb(170, 168, 168);
    border-radius: 10px;
}
.form>div:nth-child(4)>input{
    width: 30%;
    padding: 5px;
    border: 1px solid rgb(170, 168, 168);
    border-radius: 10px;
}
.form>div:nth-child(5)>input{
    width: 100%;
    padding: 5px;
    border: 1px solid rgb(170, 168, 168);
    border-radius: 10px;
}
.form>div:nth-child(6){
margin-top: 40px;
}
.form>div:nth-child(6){
color: rgb(255, 64, 102);
}
.form>div:nth-child(6)>:last-child{
    background-color: black;
    color: white;
    padding: 10px;
}
.total{
    width: 40%;
    /* background-color: aqua; */
    padding-top: 50px;
    padding-left: 50px;
    border-left: 1px solid rgb(172, 165, 165);
    
}
.coupon{
    height: 120px;
    border-top: 1px solid rgb(172, 165, 165);
    padding-top: 15px;
    border-bottom: 1px solid rgb(172, 165, 165);
    justify-content: left;
}
.coupon>input{
    width: 60%;
    padding: 5px;
    border: 1px solid rgb(170, 168, 168);
    border-radius: 10px;
    margin-right: 20px;
    margin-left: 0px;
}
.coupon>button{
    background-color: black;
    color: white;
    padding: 5px;
    width: 80px;
}