.signup{
    width:100%;
    text-align:center;
    margin-bottom:15px;
}
.signupheader{
    text-align:center;
    margin-bottom:50px;
}
.signupheader h1{
    font-family:Poppins,sans-serif;
    font-size:1.5em;
    font-weight:700;
    line-height:1.3em;
    letter-spacing:.1em;
    color:#000;
}
.signupheader p{
    width:60%;
    text-align:center;
    font-size:12px;
    font-weight:400;
    margin:10px auto 0 auto;
    font-family:Poppins,sans-serif;
    line-height:1.5;
    letter-spacing:.025em;
    color:#000;
    display:block;
}
.labels{
    display:block;
    font-weight:400;
    font-family:Poppins,sans-serif;
    line-height:1.5;
    font-size:.75em;
    letter-spacing:.3em;
    margin-bottom:5px;
    box-sizing:border-box;
    color:#000;
    text-align:left;
    margin-left:50px;
}
.inputs{
    border:1px solid #d6d6d6;
    width:80%;
    margin-bottom:30px;
    display:block;
    box-sizing:border-box;
    color:inherit;
    background-color:inherit;
    padding:8px 10px;
    line-height:1.5;
    font-family:Poppins,sans-serif;
    letter-spacing:.025em;
    margin-left:50px;
}
.signuplastdiv{
    width: 80%;
    margin-left:50px;
    margin-top: 10px;
}
.signupsubmit{
    width:49%;
    padding:13px 20px;
    float: left;
    color:#e0d9cc;
    background-color: #000;
    border: none;
}
.signupcancel{
    border:1px solid #d6d6d6;
    background-color:inherit;
    color:inherit;
    width:49%;
    padding:13px 20px;
    float:right;
    color:black;
}