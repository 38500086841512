.Container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    text-align: start;
    width: 90%;
    margin: auto;
    gap: 20px;
    margin-top: 120px;
}
.imageContainer{
    display: grid;
    grid-template-columns: 20% 80%;
}
.imageContainer img{
    width: 90%;
}
.imagebox{
    margin-top: 100px;
}
.productDetailsTopRIght{
    margin-top: 80px;
}
.Aboutproduct{
    margin-top: 20px;
}
.detailsofshipdiv{
    display: flex;
    margin-top: 20px;
    gap: 10px;
    text-align: center;
}
/* .detailsofshipdiv div{
    border: 0.1px solid gray;
    width: 150px;
}
.detailsofshipdiv img{
    background-color: white;
    width: 100px;
    height: 100px;
    margin: auto;
} */