.Containerhome{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: 90%;
    margin: auto;
    margin-top: 20px;
}
.miniNavbaratbottom{
    display: flex;
    width: 95%;
    background-color: black;
    margin: auto;
    justify-content: space-between;
}
.miniNavbaratbottom > a{

    color: white;
    text-decoration: none;
    padding: 7px;
    font-weight: bolder;
}
.instaphotoAtBottom{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    gap: 5px;
    margin: auto;
    margin-top: 10px;
    
}
.Singlebanner{
    width: 90%;
    cursor: pointer;
    margin: auto;
    margin-top: 120px;
    
}